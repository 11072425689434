import { getOptions } from "../hooks/getOptions";
import { motion } from "framer-motion";
import Link from "next/link";
import { LinkType, MenuType, OptionsType, PageType } from "../utils/baseTypes";
import { getPageById } from "../hooks/getPage";
import { getRelativePathFromLink, getUrl } from "../utils/url";
import { HeadMeta } from "../components/Header/Head";
import { getMenu } from "../hooks/getMenu";
import { Navbar } from "../components/Navbar/Navbar";
import { getStartPage } from "../hooks/getStartpage";
import { getTranslatedPageDataForLocale } from "../utils/wpmlUtils";
import { Footer } from "../components/Footer/Footer";
import { stagger_variant } from "../utils/transition";

import LazyVideo from "./../components/Header/SeiteHeader/HeroPage/Video";
import { sanitize } from "isomorphic-dompurify";
import { AnchorHTMLAttributes } from "react";
import Arrow from "../images/arrow.svg";
import { Section } from "../components/Global/Section";

export default function Custom404({
  options,
  yoastPost,
  mainMenu,
  pageData,
  footerMenu,
  legalMenu,
}: {
  options: OptionsType;
  yoastPost: PageType;
  legalMenu: MenuType;
  mainMenu: MenuType;
  footerMenu: MenuType;
  pageData: PageType;
}) {
  const { video_404, headline_404, content_404, subline_404, link_list_404 } =
    options.acf;
  return (
    <>
      <HeadMeta post={yoastPost} options={options} />
      <motion.main id="home">
        <Navbar options={options} menu={mainMenu} page={pageData} />
        <motion.section
          initial="initial"
          whileInView={"animate"}
          variants={stagger_variant}
          viewport={{ once: true }}
          className={`page-not-found fullscreen has-darkgrey-bg border-on`}
        >
          <div className={`frame frame-on h-100`}>
            <div className={`border`}>
              <div className="background-full-media">
                <div className="background-media">
                  {video_404 && (
                    <LazyVideo
                      activeVideo={video_404}
                      poster={{ desktop: false, tablet: false, mobile: false }}
                    />
                  )}
                </div>
                <div className="main-inside-wrapper">
                  <div className="columns error-info-wrapper">
                    <div className="column is-12 is-4-widescreen error-left">
                      <h1 className="title">{headline_404}</h1>
                      <span
                        className="subline is-hidden-touch is-hidden-desktop-only"
                        dangerouslySetInnerHTML={{
                          __html: sanitize(subline_404),
                        }}
                      />
                    </div>
                    <div className="column is-hidden-touch is-hidden-desktop-only is-offset-4-widescreen is-4-widescreen error-right">
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: sanitize(content_404),
                        }}
                      />

                      <div className="link-list">
                        {Array.isArray(link_list_404) &&
                          link_list_404.map((link, key) => {
                            return <GoLink key={key} link={link.page_link} />;
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-edge" />
            <div className="frame-edge" />
          </div>
        </motion.section>
        <Section className="is-hidden-desktop has-darkgrey-bg pt-0">
          <div className="column is-12 error-info-wrapper bottom">
            <span
              className="subline mb-4"
              dangerouslySetInnerHTML={{
                __html: sanitize(subline_404),
              }}
            />

            <div
              className="content mb-4"
              dangerouslySetInnerHTML={{
                __html: sanitize(content_404),
              }}
            />

            <div className="link-list">
              {Array.isArray(link_list_404) &&
                link_list_404.map((link, key) => {
                  return <GoLink key={key} link={link.page_link} />;
                })}
            </div>
          </div>
        </Section>
        <Footer options={options} menu={footerMenu} legalMenu={legalMenu} />
      </motion.main>
    </>
  );
}

interface ScrollLinkProp extends AnchorHTMLAttributes<HTMLAnchorElement> {
  link: LinkType;
  className?: string;
}

function GoLink({ link }: ScrollLinkProp) {
  return (
    <div className="link-wrapper">
      <Link
        href={getRelativePathFromLink(link.url)}
        target={link.target ? link.target : "_self"}
        rel={link.target !== "" ? link.target : "_self"}
      >
        <span dangerouslySetInnerHTML={{ __html: sanitize(link.title) }}></span>
        <Arrow width={22.7} height={15.2} />
      </Link>
    </div>
  );
}

export async function getStaticProps(context: any) {
  const options: OptionsType = await getOptions();

  const v1 = await fetch(getUrl(context.locale, context.defaultLocale, true));
  const json = await v1.json();
  const siteName = json.name;

  let startpage: PageType | null = await getStartPage();
  if (context.locale !== context.defaultLocale) {
    const wpmlData = getTranslatedPageDataForLocale(startpage, context.locale);
    if (wpmlData) {
      startpage = await getPageById(wpmlData.id);
    }
  }

  const yoast = startpage!.yoast_head_json;
  const title = "404 - " + siteName;
  yoast.title = title;
  yoast.og_title = title;
  yoast.robots.index = "noindex";
  const yoastPost = { yoast_head_json: yoast };

  const legalMenuSlug = "legal";
  const fetchlegalMenu = getMenu(
    context.locale ? `${legalMenuSlug}-` + context.locale : legalMenuSlug
  );

  const fetchfooterMenu = getMenu(
    context.locale ? "footer-" + context.locale : "footer"
  );

  const fetchmainMenu = getMenu(
    context.locale ? "main-" + context.locale : "main"
  );

  const [legalMenu, footerMenu, mainMenu] = await Promise.all([
    fetchlegalMenu,
    fetchfooterMenu,
    fetchmainMenu,
  ]);

  return {
    props: {
      options,
      yoastPost,
      legalMenu,
      footerMenu,
      mainMenu,
      pageData: startpage,
    },
    revalidate: parseInt(process.env.REVALIDATE_AFTER || "120"),
  };
}
